import React, { useState } from "react"
import ItemTitle from "./parts/components/ItemTitle"
import ResponsiveContainer from "components/UI/ResponsiveContainer"
import DataTable from "react-data-table-component"
import { navigate } from "gatsby"
import ArrowButton from "components/UI/ArrowButton"
import SectionPotensi from "./parts/SectionPotensi"

const KawasanIndustriKek = ({
  dataKek,
  dataBlok,
  dataPeluangKek,
  dataPeluangBlok,
  langCtx,
}) => {
  const [tabBlok, setTabBlok] = useState(true)
  const [tabKek, setTabKek] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")

  let datas =
    tabBlok === false
      ? tabKek === false
        ? []
        : dataKek?.dataTables
      : tabKek
      ? dataBlok?.dataTables?.concat(dataKek?.dataTables)
      : dataBlok?.dataTables

  let dataPeluangs =
    tabBlok === false
      ? tabKek === false
        ? []
        : dataPeluangKek
      : tabKek
      ? dataPeluangBlok?.concat(dataPeluangKek)
      : dataPeluangBlok

  const columns = [
    {
      name: "No",
      selector: row => row.no,
      width: "4%",
      maxWidth: "auto",
    },
    {
      name: langCtx?.language === "id" ? "Nama Kawasan" : "Region Name",
      selector: row => (
        <span
          className="cursor-pointer hover:text-bkpm-dark-blue-01 hover:font-semibold"
          onClick={() =>
            navigate(`/kawasan-industri/?id=${row.id_kawasan_industri}`)
          }
        >
          {row.name}
        </span>
      ),
      width: "25%",
      maxWidth: "auto",
    },
    {
      name: langCtx?.language === "id" ? "Kategori" : "Category",
      selector: row => row.kategori,
      width: "20%",
      maxWidth: "auto",
    },
    {
      name: langCtx?.language === "id" ? "Provinsi" : "Province",
      selector: row => row.provinsi,
    },
    {
      name: langCtx?.language === "id" ? "Kabupaten/Kota" : "District/City",
      selector: row => row.kabkot,
    },
    {
      name: langCtx?.language === "id" ? "Luas Lahan" : "Land Area",
      selector: row => row.luas,
    },
    {
      name: langCtx?.language === "id" ? "Okupansi Kawasan" : "Occupancy Area",
      selector: row => row.nama_occupancy,
    },
    {
      name: "#",
      width: "13%",
      maxWidth: "auto",
      selector: row => (
        <button
          className="bg-bkpm-dark-blue-01 text-xs  text-white font-bold rounded p-3"
          type={"button"}
          onClick={() =>
            navigate(`/kawasan-industri/?id=${row.id_kawasan_industri}`)
          }
        >
          {langCtx?.language === "id" ? "Selengkapnya" : "Detail"}
        </button>
      ),
    },
  ]
  return (
    <div className="bg-snow">
      <section className="py-12">
        <ResponsiveContainer>
          <div
            className={`${"bg-white"} backdrop-filter backdrop-blur-md bg-opacity-50 py-5 overflow-x-scroll md:overflow-x-hidden mt-5 md:mt-0`}
          >
            <div className="flex gap-3 md:gap-12  justify-center w-max md:w-auto">
              <ItemTitle
                name={
                  langCtx?.language === "id"
                    ? "Kawasan Industri dan Blok Kawasan"
                    : "Industrial Estates and Regional Blocks"
                }
                onClick={() => setTabBlok(!tabBlok)}
                isActive={tabBlok}
              />
              <ItemTitle
                name={
                  langCtx?.language === "id"
                    ? "Kawasan Ekonomi Khusus"
                    : "Special Economic Zone"
                }
                onClick={() => setTabKek(!tabKek)}
                isActive={tabKek}
              />
            </div>
          </div>

          <div>
            {dataPeluangs.length !== 0 && (
              <SectionPotensi
                dataSliderPeluangs={dataPeluangs}
                lang={langCtx.language}
              />
            )}
          </div>

          <div className="bg-white shadow-xl p-5">
            <div className="flex justify-end items-end">
              <div className="w-4/12">
                <input
                  type="text"
                  onChange={e => setSearchTerm(e.target.value)}
                  className="rounded text-xs border-gray-300 w-[100%]"
                  placeholder={"Search"}
                  classNamePrefix="addl-class"
                />
              </div>
            </div>
            <DataTable
              columns={columns}
              data={datas?.filter(item => {
                if (searchTerm === "") {
                  return item
                } else if (
                  Object.values(item).some(val =>
                    String(val).toLowerCase().includes(searchTerm.toLowerCase())
                  )
                ) {
                  return item
                }
              })}
              pagination
              paginationRowsPerPageOptions={[10]}
            />
            {/* {numTab === 1 && (
              <DataTable
                columns={columns}
                data={dataBlok?.dataTables?.filter(item => {
                  if (searchTerm === "") {
                    return item
                  } else if (
                    Object.values(item).some(val =>
                      String(val)
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                  ) {
                    return item
                  }
                })}
                pagination
                paginationRowsPerPageOptions={[10]}
              />
            )}
            {numTab === 2 && (
              <DataTable
                columns={columns}
                data={dataKek?.dataTables?.filter(item => {
                  if (searchTerm === "") {
                    return item
                  } else if (
                    Object.values(item).some(val =>
                      String(val)
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                  ) {
                    return item
                  }
                })}
                pagination
                paginationRowsPerPageOptions={[10]}
              />
            )} */}
          </div>
          <div className="mt-8 text-center">
            <ArrowButton
              buttonStyle="expanded"
              buttonClick={() => navigate(`kawasan-industri-kek`)}
            >
              {langCtx?.language === "id" ? "Lihat Semua" : "More"}
            </ArrowButton>
          </div>
        </ResponsiveContainer>
      </section>
    </div>
  )
}

export default KawasanIndustriKek
