import React from "react"

const ItemTitle = props => {
  let classActive = props.isActive && "border-b-[3px] border-bkpm-dark-blue-01"

  return (
    <div onClick={props.onClick} className={` ${classActive} cursor-pointer`}>
      {/* <a href={props.link}> */}
      <h5 className="remove-mb text-bkpm-dark-blue-01 mx-3">{props.name}</h5>
      {/* </a> */}
    </div>
  )
}

export default ItemTitle
