import React, { useEffect, useState } from "react";
import ResponsiveContainer from "components/UI/ResponsiveContainer";
import Fade from "react-reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from "react-modal";
import { Link } from "gatsby";
import { IMGNoPhoto } from "components/images";
import ICKek from "components/images/ic-symbol-kawasan-ekonomi-khusus.inline.svg";
import ICKawasanIndustriBlok from "components/images/ic-symbol-kawasan-industri-blok.inline.svg";
import ICKawasanIndustri from "components/images/ic-symbol-kawasan-industri.inline.svg";
import {
  MdLabel,
  MdMap,
  MdMyLocation,
  MdOutlinePriceChange,
  MdRoom,
} from "react-icons/md";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import axios from "axios";

const SectionPotensi = ({ dataSliderPeluangs, lang }) => {
  const [curIndex, setCurIndex] = useState(0);
  const [dataContact, setDataContact] = useState(null);
  const [contactPeluangModal, setContactPeluangModal] = useState(false);
  const [downloadDocumentModal, setDownloadDocumentModal] = useState(false);
  const [dataNegaras, setDataNegaras] = useState(null);
  const [dataUnduhKeperluans, setDataUnduhKeperluans] = useState(null);
  const [txtIdPeluang, setTxtIdPeluang] = useState(null);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    text: "",
  });
  const urlDataNegaras = `${process.env.APIURLV2}api/Webs/GetNegaras`;
  const urlUnduhDataKeperluan = `${process.env.APIURL}api/FrontEnd/GetUnduhDataKeperluans`;

  const Icon = ({ idKategori }) => {
    if (idKategori === 1) return <ICKek />;
    if (idKategori === 2) return <ICKawasanIndustriBlok />;
    if (idKategori === 3) return <ICKawasanIndustri />;
    return <ICKek />;
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(async () => {
    const result = await axios.get(urlDataNegaras, {
      headers: {
        "X-localization": lang,
      },
    });
    let data = result.data;
    if (data.isSuccess) {
      var itemData = [];
      itemData.push({
        label: lang === "id" ? "- Pilih Negara -" : " - Select Region - ",
        value: "",
      });
      data.data.map((item, index) => {
        itemData.push({
          label: `${item.nama}`,
          value: item.nama,
        });
      });
      setDataNegaras(itemData);
    }
  }, [lang]);

  useEffect(async () => {
    const result = await axios.get(urlUnduhDataKeperluan, {
      headers: {
        "X-localization": lang,
      },
    });
    let data = result.data;
    if (data.Status) {
      var itemData = [];
      itemData.push({
        label: lang === "id" ? "- Pilih Tujuan -" : " - Select Destination - ",
        value: "",
      });
      data.Data.map((item, index) => {
        itemData.push({
          label: item.nama,
          value: item.idUnduhDataKeperluan,
        });
      });
      setDataUnduhKeperluans(itemData);
    }
  }, [lang]);

  const btnKontak = (data) => {
    setContactPeluangModal(true);
    setDataContact(data);
  };

  const btnDownload = (data) => {
    setTxtIdPeluang(data.idKawasanIndustriPeluang);
    setDownloadDocumentModal(true);
  };

  const onSubmit = (data) => {
    setIsLoading({
      loading: true,
      text: "Loading...",
    });
    var URL = `${process.env.APIURLV2}api/Webs/DownloadDokumen`;
    var fd = new FormData();

    fd.append(
      "idTujuan",
      data.IdUnduhDataTujuan == null ? 2 : data.IdUnduhDataTujuan.value
    );
    fd.append("nama", data.Nama);
    fd.append("email", data.Email);
    fd.append("noTelp", data.NoTelp);
    fd.append("asalNegara", data.AsalNegara.value);
    fd.append("idPeluangDaerah", txtIdPeluang);
    fd.append("idJenisKonten", "kawasan_peluang");

    axios({
      method: "POST",
      url: URL,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (res) {
        let data = res.data;
        if (data.isSuccess) {
          setDownloadDocumentModal(false);
          reset();
          alert("Berhasil dikirim ke alamat email anda!");
        } else {
          alert("Gagal");
        }
        setIsLoading({
          loading: false,
          text: "Submit",
        });
      })
      .catch(function (response) {
        alert("Gagal, " + response);
        setIsLoading({
          loading: false,
          text: "Submit",
        });
      });
  };

  return (
    <section className="bg-white shadow-xl p-5 border-b-2 border-solid border-[#F6F7FC]">
      <ResponsiveContainer>
        <div className="">
          {dataSliderPeluangs && dataSliderPeluangs.length !== 0 && (
            <>
              <h4 className="mb-8 text-bkpm-green">
                {lang === "id" ? "Potensi Investasi" : "Investment Potential"}
              </h4>
              <Fade right>
                <Swiper
                  className="umkm-slider same-height mb-4"
                  spaceBetween={20}
                  slidesPerView={7}
                  autoplay={true}
                  onSlideChange={(e) =>
                    setCurIndex(
                      curIndex < dataSliderPeluangs.length - 1
                        ? curIndex + 1
                        : 0
                    )
                  }
                  // loop={true}
                  breakpoints={{
                    100: {
                      slidesPerView: 1,
                    },
                    200: {
                      slidesPerView: 2,
                    },
                    380: {
                      slidesPerView: 2,
                    },
                    400: {
                      slidesPerView: 3,
                    },
                    480: {
                      slidesPerView: 4,
                    },
                    786: {
                      slidesPerView: 5,
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                  }}
                >
                  {dataSliderPeluangs &&
                    dataSliderPeluangs.map((item, index) => {
                      return (
                        <SwiperSlide key={index} virtualIndex={index}>
                          <div
                            className="text-center"
                            style={{
                              background:
                                curIndex === index ? "#82c6ef" : "transparent",
                              padding: 5,
                              borderRadius: 8,
                            }}
                          >
                            <button
                              onClick={() => setCurIndex(index)}
                              className="w-10 h-10 group rounded-full bg-bkpm-dark-blue-01 flex items-center justify-center mx-auto mb-2 "
                            >
                              <center>
                                <Icon idKategori={item?.idKategori} />
                              </center>
                            </button>
                            <h6 className="uppercase text-bkpm-dark-blue-01 font-bold line-clamp-2 max-h-14 text-xs remove-mb">
                              {item?.judul}
                            </h6>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </Fade>
            </>
          )}

          {dataSliderPeluangs &&
            dataSliderPeluangs.map((item, index) => {
              if (index === curIndex) {
                return (
                  <div
                    className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-6"
                    key={index}
                  >
                    <div className={"col-span-1"}>
                      <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        autoplay={true}
                      >
                        {item.files?.map((itemx, index) => {
                          return (
                            <SwiperSlide key={index} virtualIndex={11 + index}>
                              <img
                                className="w-full rounded-xl h-40 object-cover bg-cover"
                                src={itemx?.nama || "http://google.cox"}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = IMGNoPhoto;
                                }}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>

                      <div className="grid grid-cols-2 gap-3 py-5">
                        <button
                          className="bg-bkpm-red text-white font-bold py-2 px-4 rounded w-full"
                          type={"button"}
                          onClick={() => btnKontak(item)}
                        >
                          {lang === "id" ? "Kontak" : "Contact"}
                        </button>
                        <button
                          className="bg-bkpm-green text-white font-bold py-2 px-4 rounded w-full"
                          type={"button"}
                        >
                          <Link
                            to={`/kawasan-industri/?id=${item.idKawasanIndustri}`}
                          >
                            {lang === "id" ? "Info Kawasan" : "Area Info"}
                          </Link>
                        </button>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <h3 className="line-clamp-2 font-bold text-bkpm-dark-blue-01 capitalize mb-2">
                        {item.judul}
                      </h3>
                      <div className="mb-4 md:ml-2 ml-0">
                        <p className="remove-mb font-medium flex items-center gap-3">
                          <MdLabel size={20} color="#053783" />
                          {item.namaKawasan}
                        </p>
                        <p className="remove-mb font-medium flex items-center gap-3">
                          <MdRoom size={20} color="#053783" /> {item.daerah}
                        </p>

                        <div>
                          <table class="table-auto">
                            <tbody className="font-medium">
                              <tr>
                                <td>
                                  <p className="remove-mb flex items-center gap-3 mr-1">
                                    <MdMap size={20} color="#053783" />
                                    {lang === "id"
                                      ? "Luas Kawasan"
                                      : "Area Size"}{" "}
                                  </p>
                                </td>
                                <td>: {item.luasKawasan}</td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="remove-mb flex items-center gap-3 mr-1">
                                    <MdMyLocation size={20} color="#053783" />
                                    {lang === "id"
                                      ? "Luas Potensi"
                                      : "Potential Area"}{" "}
                                  </p>
                                </td>
                                <td>: {item.luasLahan}</td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="remove-mb flex items-center gap-3 mr-1">
                                    <MdOutlinePriceChange
                                      size={20}
                                      color="#053783"
                                    />
                                    {lang === "id"
                                      ? "Nilai Investasi"
                                      : "Investment Value"}{" "}
                                  </p>
                                </td>
                                <td>: {item.nilaiInvestasi}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="mb-3">
                        <span
                          className="py-2 px-3 rounded-sm text-sm bg-gray-300 text-center text-gray-700 font-semibold cursor-pointer"
                          onClick={() => btnDownload(item)}
                        >
                          {lang === "id"
                            ? "Unduh Dokumen"
                            : "Download Document"}
                        </span>
                      </div>
                      <p
                        className="line-clamp-4"
                        dangerouslySetInnerHTML={{ __html: item.deskripsi }}
                      />
                    </div>
                  </div>
                );
              }
            })}

          <Modal
            isOpen={contactPeluangModal}
            onRequestClose={() => setContactPeluangModal(!contactPeluangModal)}
            overlayClassName="inset-0 bg-opacity-90 bg-black z-50 fixed flex items-center justify-center "
            className="p-0 w-10/12"
            style={{ content: { maxWidth: "650px" } }}
          >
            {dataContact && (
              <div className="bg-white rounded-lg p-8">
                <h5 className="capitalize">{dataContact?.judul}</h5>
                <h5>{lang === "id" ? `Kontak` : `Contact`} :</h5>
                <table className="table-auto w-full">
                  <tbody>
                    <tr className="w-full">
                      <td className="border border-emerald-500 px-4 py-2 text-bkpm-dark-blue-01 font-bold">
                        {lang == "id" ? `Nama` : `Name`}
                      </td>
                      <td className="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        {dataContact?.kontak?.nama}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="border border-emerald-500 px-4 py-2 text-bkpm-dark-blue-01 font-bold">
                        {lang == "id" ? `Alamat` : `Address`}
                      </td>
                      <td className="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        {dataContact?.kontak?.alamat}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="border border-emerald-500 px-4 py-2 text-bkpm-dark-blue-01 font-bold">
                        {lang == "id" ? `Website` : `Website`}
                      </td>
                      <td className="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        {dataContact?.kontak?.website}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="border border-emerald-500 px-4 py-2 text-bkpm-dark-blue-01 font-bold">
                        {lang == "id" ? `Surat Elektronik` : `Email`}
                      </td>
                      <td className="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        {dataContact?.kontak?.email}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="border border-emerald-500 px-4 py-2 text-bkpm-dark-blue-01 font-bold">
                        {lang == "id" ? `Telepon` : `Phone Number`}
                      </td>
                      <td className="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        {dataContact?.kontak?.telepon}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="border border-emerald-500 px-4 py-2 text-bkpm-dark-blue-01 font-bold">
                        {lang == "id" ? `No. Fax` : `Fax`}
                      </td>
                      <td className="border border-emerald-500 px-4 py-2 text-emerald-600 font-medium">
                        {dataContact?.kontak?.fax}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </Modal>

          <Modal
            isOpen={downloadDocumentModal}
            onRequestClose={() =>
              setDownloadDocumentModal(!downloadDocumentModal)
            }
            overlayClassName="inset-0 bg-opacity-90 bg-black z-50 fixed flex items-center justify-center "
            className="p-0 w-10/12"
            style={{ content: { maxWidth: "650px" } }}
          >
            <div className="bg-white rounded-lg p-8">
              <h5 className="text-bkpm-dark-blue-01">
                {lang === "id" ? "Unduh Dokumen" : "Download Document"}
              </h5>
              <div className="mb-3 flex flex-col">
                <label className="mb-2">
                  {lang === "id" ? `Nama` : `Name`}*
                </label>
                <input
                  {...register("Nama", { required: true })}
                  name="Nama"
                  type="text"
                  placeholder={lang === "id" ? `Nama` : `Name`}
                  className="rounded-md border-gray-300"
                />
                {errors.Nama && (
                  <span className="text-danger">
                    {"kolom ini wajib di isi"}
                  </span>
                )}
              </div>
              <div className="mb-3 flex flex-col">
                <label className="mb-2">
                  {lang === "id" ? `Email` : `Email`}*
                </label>
                <input
                  {...register("Email", { required: true })}
                  name="Email"
                  type="text"
                  placeholder={lang === "id" ? `Email` : `Email`}
                  className="rounded-md border-gray-300"
                />
                {errors.Email && (
                  <span className="text-danger">
                    {"kolom ini wajib di isi"}
                  </span>
                )}
              </div>
              <div className="mb-3 flex flex-col ">
                <label className="mb-2">
                  {lang === "id" ? `No. Telepon` : `Phone Number`}*
                </label>
                <input
                  {...register("NoTlp", { required: true })}
                  name="NoTlp"
                  type="text"
                  placeholder={lang === "id" ? `No. Telepon` : `Phone Number`}
                  className="rounded-md border-gray-300"
                />
                {errors.NoTlp && (
                  <span className="text-danger">
                    {"kolom ini wajib di isi"}
                  </span>
                )}
              </div>
              <div className="mb-3 flex flex-col ">
                <label className="mb-2">
                  {lang === "id" ? `Asal Negara` : `Country of Origin`}*
                </label>
                <Controller
                  control={control}
                  defaultValue={""}
                  name="AsalNegara"
                  render={({ field, ref }) => (
                    <ReactSelect
                      menuPortalTarget={
                        typeof window !== "undefined" ? document.body : null
                      }
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      inputRef={ref}
                      placeholder={
                        lang === "id"
                          ? `- Asal Negara -`
                          : `- Country of Origin -`
                      }
                      classNamePrefix="addl-class"
                      options={dataNegaras}
                      value={getValues("AsalNegara")}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                    />
                  )}
                />
              </div>
              <div className="mb-3 flex flex-col ">
                <label className="mb-2">
                  {lang === "id" ? `Tujuan` : `Destination`}*
                </label>
                <Controller
                  control={control}
                  defaultValue={""}
                  name="IdUnduhDataTujuan"
                  render={({ field, ref }) => (
                    <ReactSelect
                      menuPortalTarget={
                        typeof window !== "undefined" ? document.body : null
                      }
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      inputRef={ref}
                      placeholder={
                        lang === "id" ? `- Tujuan -` : `- Destination -`
                      }
                      classNamePrefix="addl-class"
                      options={dataUnduhKeperluans}
                      value={getValues("IdUnduhDataTujuan")}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                    />
                  )}
                />
              </div>

              <div className="flex justify-end mt-10">
                <button
                  onClick={handleSubmit(onSubmit)}
                  type={"submit"}
                  disabled={isLoading.loading ? true : false}
                  className="bg-bkpm-light-blue-01 text-white px-4 py-2 rounded-md"
                >
                  {isLoading.loading ? isLoading.text : "Submit"}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </ResponsiveContainer>
    </section>
  );
};

export default SectionPotensi;
